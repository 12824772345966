<template>
  <v-container class="mt-3 mb-6">
    <v-row class="mb-3" color="transparent">
      <v-col cols="12" sm="6">
        <v-card flat height="100%">
          <v-card-title> Orden de compra </v-card-title>
          <v-card-text>
            <b>{{ this.company.name }}</b>
            <br />
            {{ get_name_typeId(this.company.type_id) }} :
            {{ this.company.identification_id }}<br />
            {{ this.company.address + " , " + this.company.city }}<br />
          </v-card-text>
          <v-card-text>
            <h3></h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card elevation="4" height="100%">
          <v-toolbar flat dense>
            <v-toolbar-title>Proveedor</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              fab
              x-small
              @click="clientsLst = !clientsLst"
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            Nombre: {{ this.client.name }} <br />
            {{ get_name_typeId(this.client.type_id) }} :
            {{ this.client.identification_id }}<br />
            Dirección: {{ this.client.address }}<br />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-list rounded>
      <v-list-item
        v-for="(child, index) in stock_order_items"
        :key="index"
        @click="edit_item(child)"
        v-touch="{
          left: () => swipe('Left'),
        }"
      >
        <v-list-item-avatar>
          <v-img :src="getImagePhoto(child.account, child.picture)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-black"
            v-text="child.product_name"
          />

          <v-list-item-subtitle>
            {{ child.brand + " Ref:" + child.reference }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ child.size + " " + child.uom + " X " + child.quantity }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ child.note }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          {{
            "$ " +
            (
              parseFloat(child.price) * parseFloat(child.quantity)
            ).toLocaleString()
          }}</v-list-item-action
        >
      </v-list-item>
      <v-toolbar flat class="font-weight-black mt-1">
        {{ "Cantidad : " + totals.quantity }}
        <v-spacer></v-spacer>
        {{ "Total : $ " + parseFloat(totals.total).toLocaleString(2) }}
      </v-toolbar>
    </v-list>

    <v-row color="transparent">
      <v-col cols="12" sm="6">
        <v-card height="100%" class="mt-6">
          <v-toolbar flat dense>
            <v-toolbar-title>Condiciones Comerciales</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              fab
              x-small
              color="success"
              @click="payment_dialogo = !payment_dialogo"
              :disabled="!this.totals.unpaid > 0"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-list dense>
              <v-list-item v-for="(item, index) in payments" :key="index">
                <v-list-item-content dark class="pa-0 ma-0">
                  <v-list-item-title v-text="item.payment_concept" />
                </v-list-item-content>
                <v-list-item-action
                  v-text="'$ ' + item.payment_value"
                  class="pa-0 ma-0"
                />
                <v-list-item-icon>
                  <v-icon @click="delete_payment(index)">mdi-close</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- concepts -->
    <v-dialog
      v-model="concepts_dialog"
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form ref="conceptForm" v-model="validConcept" @submit="add2concepts">
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>Por concepto de</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon @click="concepts_dialog = !concepts_dialog">
              mdi-close
            </v-icon>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="concept.item_code"
                  hide-details
                  :items="concept_lst"
                  item-value="code"
                  item-text="value"
                  label="Concepto"
                  placeholder="Concepto"
                  :rules="f_required"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="concept.item_note"
                  label="Nota / Observación"
                  hide-details
                  :rules="f_required"
                  outlined
                  autofocus
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="concept.item_reference"
                  label="Documento Referencia"
                  :rules="f_required"
                  hide-details
                  outlined
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="concept.item_value"
                  label="Valor"
                  placeholder="Valor"
                  type="number"
                  class="right-input"
                  clearable
                  :rules="f_required"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="success" type="submit">Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- payes -->
    <v-dialog
      v-model="payment_dialogo"
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>Registrar Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="payment_dialogo = !payment_dialogo">
              mdi-close
            </v-icon>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="pay_mode"
                  :items="FP"
                  return-object
                  item-value="code"
                  item-text="value"
                  label="Forma de pago"
                  placeholder="Forma de pago"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="payment.payment_reference"
                  label="Documento / Referencia"
                  :error="payRefe"
                  @keyup="updateRefe"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="payment.payment_value"
                  label="Valor"
                  type="number"
                  class="right-input"
                  :error="payValue"
                  clearable
                  :rule="payRule"
                  outlined
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!payvalid"
              color="success"
              class="mr-4"
              type="submit"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Search -->
    <v-dialog
      v-model="search_dialog"
      width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :permanent="search_dialog"
    >
      <v-card>
        <v-card-title flat class="pa-0 mb-3" color="transparent">
          <v-toolbar dark color="secondary">
            <v-toolbar-title>Materia Prima</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              x-small
              fab
              elevation="0"
              @click="search_dialog = !search_dialog"
            >
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-fab-transition>
                <v-btn
                  color="pink"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                  @click="addProduct()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 pt-3 mt-3">
          <v-expansion-panels flat>
            <v-expansion-panel v-for="value in categories" :key="value">
              <v-expansion-panel-header
                class="font-weight-black text-uppercase pt-1 pb-1"
                dark
              >
                <span>
                  <v-avatar>
                    <v-img
                      :src="
                        getImagePhoto(
                          byCategories[value][0].account,
                          byCategories[value][0].picture
                        )
                      "
                    />
                  </v-avatar>
                  {{ value }}
                </span>

                <v-spacer></v-spacer>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item
                    v-for="(child, index) in byCategories[value]"
                    :key="index"
                    @click="add_item(child)"
                    v-touch="{
                      left: () => swipe('Left'),
                    }"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :src="getImagePhoto(child.account, child.picture)"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-black"
                        v-text="
                          child.product_name +
                          ' x ' +
                          child.size +
                          ' ' +
                          child.uom
                        "
                      />
                      <v-list-item-subtitle
                        v-html="child.brand + ' Ref:' + child.reference"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      {{
                        "$ " + parseFloat(child.price).toLocaleString()
                      }}</v-list-item-action
                    >
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-snackbar
            v-model="productAdded"
            :timeout="timeout"
            absolute
            centered
            dark
            color="orange"
            elevation="24"
          >
            Producto agregado ...
          </v-snackbar>
          <v-toolbar dark>
            {{ "Cantidad : " + totals.quantity }}
            <v-spacer></v-spacer>
            {{ "Total : $ " + parseFloat(totals.total).toLocaleString(2) }}
          </v-toolbar>
        </v-card-actions>
        <ProductForm
          table="raw_material"
          :item="product"
          :dialog="product_dialog"
          :newItem="newProduct"
          :categories="categories"
          :store="store"
          @close="product_dialog = false"
          :subcategories="subcategories"
          :brands="brands"
          :suppliers="suppliers"
          @refresh="refresh_products()"
        />
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      right
      class="navigation-drawer"
      :value="showPrint"
      width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      app
    >
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Comprobante Ingreso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab dark @click="showPrint = !showPrint">
            <v-icon x-large> mdi-close-circle-outline </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img
            height="100%"
            width="100%"
            class="white"
            :src="invoiceImg"
          ></v-img>
        </v-card-text>
      </v-card>
      <v-toolbar class="fixed-footer">
        <v-spacer></v-spacer>
        <v-btn color="success" x-large> Imprimir </v-btn>
      </v-toolbar>
    </v-navigation-drawer>

    <!-- Party -->
    <v-dialog
      v-model="clientsLst"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :permanent="clientsLst"
    >
      <v-card>
        <v-card-title flat class="pa-0">
          <v-toolbar dark dense color="secondary">
            <v-toolbar-title>Proveedores</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn x-small fab @click="clientsLst = !clientsLst">
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
            <template v-slot:extension>
              <v-fab-transition>
                <v-btn
                  color="pink"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                  @click="addParty()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="client_header"
            :items="data_list"
            item-key="code"
            sort-by="name"
            mobile-breakpoint="10"
            class="table-cursor mt-3"
            hide-default-footer
            @click:row="handleClick"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }} <br />
              <small>
                {{ item.email }}
              </small>
            </template>
            <template v-slot:[`item.mobile`]="{ item }">
              <small>{{ item.mobile }}</small>
              <v-icon>mdi-account-box-outline</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-model="client_text"
            label="Buscar Contacto"
            append-icon="mdi-account-search"
            @click:append="get_client"
          />
        </v-card-actions>
        <PartyForm
          table="suppliers"
          :item="client"
          :dialog="client_dialog"
          :newItem="newParty"
          @close="client_dialog = false"
          @refresh="refresh_clients()"
        />
      </v-card>
    </v-dialog>

    <v-bottom-navigation dark grow class="pa-3" app>
      <v-form>
        <v-text-field
          v-model="sku"
          id="sku"
          ref="skuID"
          label=""
          placeholder="Código de Barras"
          hide-details
          rounded
          dense
          outlined
          autofocus
          prepend-inner-icon="mdi-barcode-scan"
          append-icon="mdi-help"
          @click:append="search_dialog = !search_dialog"
          color="yellow"
        ></v-text-field>
      </v-form>
    </v-bottom-navigation>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Item Edit -->
    <v-dialog
      v-model="edit_item_dialog"
      max-width="400px"
      :scrollable="$vuetify.breakpoint.xsOnly"
    >
      <v-card flat tile>
        <div v-if="itemSelected.picture !== ''">
          <v-img
            contain
            v-if="itemSelected.picture"
            height="20vh"
            aspect-ratio="2"
            class="white"
            :src="getImagePhoto(itemSelected.account, itemSelected.picture)"
          >
            <v-app-bar flat dense height="60px" color="transparent">
             
              <v-spacer></v-spacer>
              <v-btn
                fab
                x-small
                top
                right
                @click="edit_item_dialog = !edit_item_dialog"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-img>
        </div>
        <v-card-title v-else>
          <v-app-bar flat dense height="60px" color="transparent">
            <v-spacer></v-spacer>
            <v-btn fab top right @click="add_dialog = !add_dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>

        <v-card-title
          v-html="
            itemSelected.product_name +
            '<br>' +
            itemSelected.brand +
            ' Ref:' +
            itemSelected.reference +
            '<br>' +
            itemSelected.size +
            ' x ' +
            itemSelected.uom
          "
        />
        <v-card-text class="pro-text" v-html="itemSelected.description" />

        <v-card-text color="white">
          <v-row class="ma-0">
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="itemSelected.quantity"
                :value="itemSelected.quantity"
                label="Cantidad"
                outlined
                rounded
                class="center-input input"
                type="number"
                required
                :rules="qtyRules"
              />
            </v-col>

            <v-col cols="6" sm="6" class="text-right">
              <v-btn
                v-if="addItm"
                color="success"
                icon
                x-large
                @click="add2cart()"
              >
                <v-icon large right> mdi-cart-plus </v-icon>
              </v-btn>
              <v-btn
                v-else
                color="success"
                icon
                large
                @click="edit_item_dialog = !edit_item_dialog"
              >
                <v-icon left> mdi-cart-check </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="itemSelected.note"
                auto-grow
                label="Note"
                rows="1"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createParty from "../utils/createParty.js";
import createPayment from "../utils/createPayment.js";
import create_item from "../utils/create_item.js";
import createItem from "../utils/createItem.js";
import PartyForm from "../components/PartyForm.vue";
import ProductForm from "../components/ProductForm.vue";

export default {
  components: { PartyForm, ProductForm },
  data() {
    return {
      edit_item_dialog: false,
      clientsLst: false,
      client_dialog: false,
      product_dialog: false,
      search_dialog: false,
      search_valid: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      FP: null,
      CT: null,
      MR: null,
      colors: [],
      inventory_lst: [],
      stock_order_items: [],
      item: createItem(),
      product: createItem(),
      itemSelected: createItem(),
      timeout: 250,
      timeout_error: 2000,
      productAdded: false,
      sku: null,
      client_text: "",
      data_list: [],
      newParty: false,
      newProduct: false,
      payment_dialogo: false,
      concepts_dialog: false,
      loading_status: false,
      validConcept: false,
      payvalid: false,
      showPrint: false,
      invoiceImg: null,
      signature: null,
      payment: createPayment(),
      totals: { quantity: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      qtyRules: [
        (v) => !!v || "Campo Requerido",
        (v) => (v && v >= 1) || "No puede ser cero",
      ],
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      header_inventory: [
        {
          text: "",
          align: "center",
          value: "picture",
          width: 50,
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      header_cart: [
        {
          text: "",
          align: "center",
          value: "picture",
          width: 50,
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      company: createParty(),
      concept: create_item(),
      client: createParty(),
      concepts: [],
      concept_lst: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        code: "01",
        value: "EFECTIVO",
      },
      signatureStock: null,
      f_required: [(v) => !!v || "! Campo requerido !"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      client_header: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
        {
          text: "",
          align: "end",
          sortable: true,
          value: "mobile",
        },
      ],
      byCategories: [],
      item_categories: [],
      addItm: false,
      store: "",
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
    };
  },
  mounted() {
    this.company = this.$store.state.company;
    this.store = this.$store.state.company.code;
    this.concept_lst = window.settings["INGRESOS"];
    console.log(this.concept_lst);
    this.FP = window.settings.OP;
    this.CT = window.settings.CATEGORIA;
    this.MR = window.settings.MARCA;
    this.colors = window.settings.COLOR;
    this.get_client();
    if (!this.client.code) this.clientsLst = true;

    this.search_inventory();
  },
  methods: {
    swipe(direction) {
      console.log(direction);
    },
    addParty() {
      this.item = createParty();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newParty = true;
      this.client_dialog = true;
    },
    addProduct() {
      this.product = createItem();
      this.product.account = this.$store.state.profile.account;
      this.valid = false;
      this.newProduct = true;
      this.product_dialog = true;
    },
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      console.log("=== End ===");
    },
    get_name_typeId(e) {
      var index = this.id_lst.findIndex((itm) => itm.code === e);
      if (index >= 0) {
        return this.id_lst[index].name;
      }
    },
    get_concept_text(e) {
      var index = this.concept_lst.findIndex((itm) => itm.code === e);
      if (index >= 0) {
        return this.concept_lst[index].value;
      }
    },
    refresh_clients() {
      this.client_dialog = false;
    },
    refresh_products() {
      this.product_dialog = false;
    },
    add2concepts(e) {
      e.preventDefault();
      this.$refs.conceptForm.validate();
      if (this.validConcept) {
        const newItem = { ...this.concept };
        newItem.item_value = parseFloat(newItem.item_value);
        newItem.price = parseFloat(newItem.item_value);
        newItem.item_concept = this.get_concept_text(newItem.item_code);
        this.concepts.push(newItem);
        this.updateTotal();
        this.concept = create_item();
      }
    },
    updateTotal() {
      this.totals.quantity = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;

      this.stock_order_items.forEach((itm) => {
        this.totals.quantity += parseFloat(itm.quantity);
        this.totals.total += parseFloat(itm.quantity) * parseFloat(itm.price);
      });

      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;

      if (this.totals.unpaid === 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.save_receipt();
      } else {
        this.topay = false;
      }
    },
    get_client() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "suppliers",
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.data_list = data;
      });
    },
    edit_item(e) {
      this.addItm = false;
      this.itemSelected = e;
      this.edit_item_dialog = true;
    },
    add_item(e) {
      e.quantity = 1;
      this.addItm = true;
      this.itemSelected = e;
      this.edit_item_dialog = true;
    },
    handleClick(e) {
      this.client = e;
      this.clientsLst = false;
    },
    addPayment(e) {
      e.preventDefault();

      if (this.payment.payment_value <= this.totals.unpaid) {
        this.payment.payment_concept = this.pay_mode.value;
        this.payment.payment_code = this.pay_mode.code;
        this.payments.push({ ...this.payment });
        this.updateTotal();
        this.pay_mode = {
          code: "01",
          value: "EFECTIVO",
        };
      } else {
        this.payValue = true;
        this.payment.payment_value = 0;
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    delete_payment(e) {
      this.payments.splice(e, 1);
      this.updateTotal();
    },
    save_receipt() {
      var qry = {
        doc: {
          doc_type: "CS-IN",
          doc_value: this.totals.paid,
          account: this.$store.state.profile.account,
          userId: window.profile.email,
          paidTo: this.client,
        },
        items: this.concepts,
        payments: this.payments,
      };
      console.log(qry);
      // webserver("receipt_save", qry, (data) => {
      //   this.resetDoc();
      //   this.loading_vendors = false;
      //   console.log(data);
      //   this.print_doc(data);
      // });
    },
    add2cart() {
      console.log(this.itemSelected);
      const newItem = { ...this.itemSelected };
      newItem.quantity = parseInt(newItem.quantity);
      // var factor = parseInt(newItem.descuento) / 100;
      // newItem.discount = Math.round(newItem.precio * factor);
      newItem.price = parseInt(newItem.price);
      var index = this.stock_order_items.findIndex(
        (itm) => itm.code === newItem.code
      );
      if (index === -1) {
        this.stock_order_items.push(newItem);
      } else {
        this.stock_order_items[index].quantity =
          this.stock_order_items[index].quantity + parseFloat(newItem.quantity);
      }
      this.productAdded = true;
      this.edit_item_dialog = false;
      this.updateTotal();
    },
    search_inventory() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [],
      };
      this.loading_status = true;
      console.log(qry);
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        console.log(ctgry);

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.byCategories = ctgry;
        this.categories = Object.keys(ctgry).sort();

        var sbctgry = data.reduce(function (r, a) {
          r[a.subcategory] = r[a.subcategory] || [];
          r[a.subcategory].push(a);
          return r;
        }, Object.create(null));
        this.subcategories = Object.keys(sbctgry).sort();

        var brnd = data.reduce(function (r, a) {
          r[a.brand] = r[a.brand] || [];
          r[a.brand].push(a);
          return r;
        }, Object.create(null));
        this.brands = Object.keys(brnd).sort();

        var spplr = data.reduce(function (r, a) {
          r[a.supplier] = r[a.supplier] || [];
          r[a.supplier].push(a);
          return r;
        }, Object.create(null));
        this.suppliers = Object.keys(spplr).sort();
      });
    },
    resetDoc() {
      this.payments = [];
      this.concepts = [];
      this.client = createParty();
    },
    print_doc(data) {
      var LS = 50;
      var itemsQty = data.items.length * LS * 3;
      const elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      const ctx = elem.getContext("2d");

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(window.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(window.seller.PartyName, center, line);
      line += LS;
      ctx.fillText("Nit : " + window.seller.PartyIdentification, center, line);

      line += LS;
      ctx.fillText(
        window.seller.AddressLine + ", " + window.seller.CityName,
        center,
        line
      );
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("RECIBO DE CAJA", center, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText("No. " + data.document.doc_number, center, line);
      line += LS;
      ctx.fillText(data.document.issueDate, center, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("C.C./Nit : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.fillText("Nombre    : " + data.buyer.PartyName, left, line);
      line += LS;
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Concepto", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      var tdiscount = 0;

      data.items.forEach((row) => {
        var price = row.price * row.quantity;
        var discount = row.discount * row.quantity;
        ctx.textAlign = "start";
        ctx.fillText(row.item_concept, left, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_note, left, line);
        ctx.textAlign = "end";
        ctx.fillText(price.toLocaleString(), right, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_reference, left, line);

        line += LS;
        total = total + price;
        tdiscount = tdiscount + discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      line += LS;

      ctx.textAlign = "center";
      ctx.fillText("Formas de pago", center, line);
      line += LS;

      data.payment.forEach((item) => {
        item.valor = parseInt(item.valor);
      });
      console.log(data);
      data.payment.forEach((row) => {
        ctx.textAlign = "start";
        ctx.fillText(
          row.payment_concept + " - " + row.payment_reference,
          left,
          line
        );
        ctx.textAlign = "end";
        ctx.fillText(row.payment_value.toLocaleString(), right, line);
        line += LS;
      });
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
      // var a = window.open("", "", "height=600, width=400");
      // a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      // a.print();
      // a.document.close();
      // setTimeout(() => {
      //   a.close();
      // }, 500);
    },
    setSku() {
      setTimeout(() => {
        document.getElementById("sku").focus();
      }, 50);
    },
    getImagePhoto: function (account, src) {
      var url = "https://smartchef.pro/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    restQty() {
      if (this.itemSelected.quantity > 1) {
        this.itemSelected.quantity = this.itemSelected.quantity - 1;
      }
    },
    addQty() {
      this.itemSelected.quantity = this.itemSelected.quantity + 1;
      console.log(this.itemSelected.quantity);
    },
  },
  computed: {
    ttotals() {
      const ttotals = this.stock_order_items.reduce(
        (acc, d) => {
          acc.price += d.price;
        },
        {
          price: "0",
        }
      );
      ttotals.fat = ttotals.fat.toFixed(1);
      return ttotals;
    },
  },
  watch: {
    edit_item_dialog(visible) {
      if (visible) {
        this.updateTotal();
      } else {
        this.updateTotal();
      }
    },
  },
};
</script>

<style>
.sign {
  border: darkgray solid 1px;
}
.center-input input {
  text-align: center;
  width: 10px;
}
.pro-text {
  flex-grow: 1;
  overflow: auto;
  max-height: 40vh;
}
</style>
